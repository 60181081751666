import Is from './is';

export function transform(el, transformValue) {
  el.style.webkitTransform = transformValue;
  el.style.msTransform = transformValue;
  el.style.transform = transformValue;
}

/**
 * Translate 3D
 * translate(element, translateX, translateY, unity)
 * ►►►  unity → 'px' or '%' by default
 */
export function translate(el, x, y, u) {
  let _u = Is.und(u) ? '%' : u;
  el.style.webkitTransform = 'translate3d(' + x + _u + ',' + y + _u + ',0)';
  el.style.msTransform = 'translate3d(' + x + _u + ',' + y + _u + ',0)';
  el.style.transform = 'translate3d(' + x + _u + ',' + y + _u + ',0)';
}

export function getTranslate(el) {
  const translate = {}
  if(!window.getComputedStyle) return;

  const style = getComputedStyle(el);
  const transform = style.transform || style.webkitTransform || style.mozTransform;

  let mat = transform.match(/^matrix3d\((.+)\)$/);
  if(mat) return parseFloat(mat[1].split(', ')[13]);
  mat = transform.match(/^matrix\((.+)\)$/);
  translate.x = mat ? parseFloat(mat[1].split(', ')[4]) : 0;
  translate.y = mat ? parseFloat(mat[1].split(', ')[5]) : 0;

  return translate;
}
