let Scroll = {
  to (final, duration, cb) {
    var start = window.scrollY || document.documentElement.scrollTop,
        currentTime = null,
        speed = duration || 600;

    var animateScroll = function(timestamp){
      if (!currentTime) currentTime = timestamp;
      var progress = timestamp - currentTime;
      if(progress > speed) progress = speed;
      var val = Math.easeInOutQuad(progress, start, final - start, speed);
      window.scrollTo(0, val);
      if(progress < speed) {
          window.requestAnimationFrame(animateScroll);
      } else {
        cb && cb();
      }
    };

    window.requestAnimationFrame(animateScroll);
  },
  // scrollTo (final, duration, cb) {
  //   const start = window.scrollY || document.documentElement.scrollTop,
  //         speed = duration || 600
  //   let currentTime = 0

  //   const time = Math.max(0.1, Math.min(Math.abs(start - final) / speed, 0.8))

  //   debug(final)

  //   const tick = () => {
  //     currentTime += 1 / 60

  //     const p = currentTime / time
  //     const t = Math.easeOutCirc(p)

  //     if (p < 1) {
  //       requestAnimationFrame(tick)
  //       window.scrollTo(0, start + (final - start) * t)
  //     } else {
  //       window.scrollTo(0, 0)
  //       cb && cb()
  //     }
  //   }

  //   tick()
  // },

  top (duration, cb) {
    this.to(0, duration, cb)
  },
}

export function scrollTo (final, duration, cb) {
  Scroll.to(final, duration, cb);
}

export function scrollToTop (duration, cb) {
  Scroll.top(duration, cb);
}

export function scrollZero () {
  // window.scrollTo(0, 0);
  window.scroll(0, 0);
}
