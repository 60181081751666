let Attr = {

  /*
    Class manipulation functions
  */
  hasClass (el, className) {
    if (el.classList) return el.classList.contains(className);
    // else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    else return !!el.getAttribute('class').match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  },

  addClass (el, className) {
    var classList = className.split(' ');
    if (el.classList) el.classList.add(classList[0]);
    else if (!this.hasClass(el, classList[0])) {
      // el.className += " " + classList[0];
      var c = el.getAttribute('class');
      el.setAttribute('class', c + " " + classList[0]);
    }
    if (classList.length > 1) this.addClass(el, classList.slice(1).join(' '));
  },

  removeClass (el, className) {
    var classList = className.split(' ');
    if (el.classList) el.classList.remove(classList[0]);
    else if(this.hasClass(el, classList[0])) {
        var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
        // el.className=el.className.replace(reg, ' ');
        var c = el.getAttribute('class');
        el.setAttribute('class', c.replace(reg, ' '))
    }
    if (classList.length > 1) this.removeClass(el, classList.slice(1).join(' '));
  },

  toggleClass (el, className, bool) {
    if (!el) return
    if(bool) this.addClass(el, className);
    else this.removeClass(el, className);
  },

  setAttributes (el, attrs) {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  },

}

export default Attr;
