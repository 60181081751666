import { scrollToTop } from '../utils/scroll';

export class BackToTop {
  constructor(app) {
    this.app = app;

    // Scroll back to top
    this.progressWrap = document.querySelector('.back-to-top');
    this.progressHeight = 0;
    this.path = document.querySelector('.progress-wrap path');
    this.pathLength = null;
  }

  init() {
    debug('call init from BackToTop');

    this.pathLength = this.path.getTotalLength();
    this.path.style.transition = this.path.style.WebkitTransition = 'none';
    this.path.style.strokeDasharray = this.pathLength + ' ' + this.pathLength;
    this.path.style.strokeDashoffset = this.pathLength;
    this.path.getBoundingClientRect();
    this.path.style.transition = this.path.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

    this.progressWrap.addEventListener('click', (e) => {
      e.preventDefault();
      // Util.scrollToTop(variables.transition_slow);
      scrollToTop(800);
      return false;
    });
  }

  // Used for the back to top progress visuel
  getProgressHeight () {
    // return document.body.clientHeight - window.innerHeight;
    return document.documentElement.scrollHeight - window.innerHeight;
  }

  // Used for the back to top progress visuel
  updateProgress () {
    var progress = this.pathLength - (this.app.scroll.target * this.pathLength / this.progressHeight);
    this.path.style.strokeDashoffset = progress;
  }

  _onPageReady() {
    this._onResize();
  }

  _onPageLoaded() {
    setTimeout(() => {
      this._onResize();
    }, 150);
  }

  _onScroll() {
    this.updateProgress();
  }

  _onResize() {
    this.progressHeight = this.getProgressHeight();
    this.updateProgress();
  }
}
