/*
  DOM manipulation
*/

export function getChildrenByClassName (el, className) {
  var children = el.children,
    childrenByClass = [];
  for (var i = 0; i < children.length; i++) {
    if (this.hasClass(children[i], className)) childrenByClass.push(children[i]);
  }
  return childrenByClass;
}
  
// Find a parent element by its tag
export function findParentByTagName (element, tagName) {
  let parent = element
  while (parent !== null && parent.tagName !== tagName.toUpperCase()) {
    parent = parent.parentNode
  }
  return parent
}
  
// Animate height of an element
export function setHeight (start, to, element, duration, cb) {
  var change = to - start,
      currentTime = null;

  var animateHeight = function(timestamp){
    if (!currentTime) currentTime = timestamp;
    var progress = timestamp - currentTime;
    var val = parseInt((progress/duration)*change + start);
    element.setAttribute("style", "height:"+val+"px;");
    if(progress < duration) {
      window.requestAnimationFrame(animateHeight);
    } else {
      cb();
    }
  };

  //set the height of the element before starting animation -> fix bug on Safari
  element.setAttribute("style", "height:"+start+"px;");
  window.requestAnimationFrame(animateHeight);
}
  
// Show or hide an element
export function show ( element, style ) {
  if (style) {
    element.style.display = style
  } else {
    element.style.display = 'block'
  }
}

export function hide ( element ) {
  element.style.display = 'none'
}
  
export function isInViewport (elem) {
  const scroll    = window.scrollY || window.pageYOffset
  const boundsTop = elem.getBoundingClientRect().top + scroll

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight,
  }
  const bounds = {
    top: boundsTop,
    bottom: boundsTop + elem.clientHeight,
  }

  const inview = ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom )
    || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );

  // debug('inview')
  // debug(inview, elem)

  return inview;
}

export function getCoords(elem) { // crossbrowser version
  var box = elem.getBoundingClientRect();
  // debug(box);

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
  var smoothTop = 0;
  
  if (app.SmoothScroll) {
    smoothTop = app.SmoothScroll.getScrollDoc();
  }
  
  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;
  
  debug('scrollTop', scrollTop);
  debug('smooth top', smoothTop);
  debug('clientTop', clientTop);

  var top  = box.top +  scrollTop + smoothTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}