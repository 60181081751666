import gsap from 'gsap';
import { html } from '../utils/environment';
import Attr from '../utils/attributes';
import { scrollZero } from '../utils/scroll';

export class PageLoader {
  constructor(app) {
    this.app = app;

    this.DOM = {
      el: document.getElementById('pageloader'),
      progress: document.getElementById('pageloader__progress'),

      overlay: document.getElementById('overlay'),
      path: document.getElementById('overlay-path')
    };

    this.nbLoaded = 0;
    this.nbTotal = 1; // start at 1 because we have the 'init' of all modules to wait
    this.progress = 0;
    this.progress_percent = 0;

    // Overlay
    this.paths = {
      base:   "M 0 100 V 100 Q 50 100 100 100 V 100 z",
      middle: "M 0 100 V 50 Q 50 0 100 50 V 100 z",
      end:    "M 0 100 V 0 Q 50 0 100 0 V 100 z",
    };
    this.isAnimating = false;
    this.isOpened    = false;

    this.tlOpen = gsap.timeline({
      onStart: () => {
        // debug('overlay tl started');
        this.isOpened = true;
        Attr.addClass(this.DOM.overlay, 'is-opened');
      },
      onComplete: () => {
        // debug('overlay tl completed');
        this.isAnimating = false;
        // scrollZero();
      },
      // onReverseComplete: () => {
      //   debug('overlay tl reverse completed');
      //   this.isOpened = false;
      //   this.isAnimating = false;
      //   setTimeout(() => {
      //     Attr.removeClass(overlay, 'is-opened');
      //     Attr.removeClass(overlay, 'flipped');
      //   }, 500);
      // }
    })
    // .from(overlay_path, 0, {
    //   attr: { d: base },
    //   ease: 'Power2.easeIn'
    // })
    .to(this.DOM.path, {
      attr: { d: this.paths.middle },
      ease: 'Power2.easeIn',
      duration: .3,
      // fill: '#efefef',
    })
    .to(this.DOM.path, {
      attr: { d: this.paths.end },
      ease: 'Power2.easeOut',
      duration: .3,
      // fill: '#fff',
    }).pause();

    this.tlClose = gsap.timeline({
      onStart: () => {
        // debug('overlay tl started');
        this.isOpened = true;
        Attr.addClass(this.DOM.overlay, 'is-opened');
      },
      onComplete: () => {
        // debug('overlay tl completed');
        this.isAnimating = false;
        this.isOpened = false;

        setTimeout(() => {
          Attr.removeClass(this.DOM.overlay, 'is-opened');
          Attr.removeClass(this.DOM.overlay, 'flipped');
        }, 300);
      },
      // onReverseComplete: () => {
      //   debug('overlay tl reverse completed');
      //   this.isOpened = false;
      //   this.isAnimating = false;
      //   setTimeout(() => {
      //     Attr.removeClass(overlay, 'is-opened');
      //     Attr.removeClass(overlay, 'flipped');
      //   }, 500);
      // }
    })
    // .from(overlay_path, 0, {
    //   attr: { d: base },
    //   ease: 'Power2.easeIn'
    // })
    .to(this.DOM.path, {
      attr: { d: this.paths.middle },
      ease: 'Power2.easeIn',
      duration: .3,
      // fill: '#efefef',
    })
    .to(this.DOM.path, {
      attr: { d: this.paths.base },
      ease: 'Power2.easeOut',
      duration: .3,
      // fill: '#fff',
    }).pause();
  }

  init() {
    debug('call init from pageLoader');
    this.increment();
  }


  // PROGRESS helper functions
  add(nb) {
    this.nbTotal += nb;
  }

  increment() {
    this.nbLoaded++;
    this.update();
  }

  update() {
    if ( this.nbTotal > 0 ) {
      this.progress = this.nbLoaded / this.nbTotal;
      this.progress_percent = this.progress * 100;
    }

    // debug(this.nbLoaded, this.nbTotal);
    // debug('load progress : ' + this.progress_percent + '%');

    // this.pageloaderTextPercent.innerHTML = Math.round( progress ) + '%';
    this.DOM.progress.style.transform = 'scaleX(' + this.progress + ')';
  }

  reset () {
    this.nbTotal = 1; // start at 1 because we have the 'init' of all modules to wait
    this.nbLoaded = 0;
    this.progress = 0;
    this.progress_percent = 0;
    this.DOM.progress.style.transform = 'scaleX(0)';
  }


  // OVERLAY helper functions

  toggle() {
    this.isAnimating = true;

    if (this.isOpened === false) {
      return this.open();
    } else {
      return this.close();
    }
  }

  open() {
    this.isAnimating = true;
    return this.tlOpen.restart();
  }

  close() {
    this.isAnimating = true;
    Attr.addClass(overlay, 'flipped');
    this.isAnimating = true;
    // return this.tl.reverse();
    return this.tlClose.restart();
  }


  // HOOKS

  _onPageReady() {
    Attr.removeClass(html, 'is-loading');
    Attr.addClass(html, 'is-ready');
    return this.close();
  }

  _onPageLoaded() {
    Attr.addClass(html, 'is-loaded');
  }

  _onPageOut() {
    this.reset();
    Attr.removeClass(html, 'is-loaded');
    // Attr.removeClass(html, 'is-ready');
    // setTimeout(() => {
    //   Attr.addClass(html, 'is-loading');
    // }, 30);
    scrollZero();
    return;
  }

  _onPageChange() {
    // this.reset();
    Attr.removeClass(html, 'is-loaded');
    // Attr.removeClass(html, 'is-ready');
    setTimeout(() => {
      Attr.addClass(html, 'is-loading');
    }, 30);
    return this.open();
  }

}
