// var imagesLoaded = require('imagesloaded');
// import FontFaceObserver from 'fontfaceobserver';

import { html, body, isIE, isMobile, intersectionObserverSupport, cssMixBlendMode, reducedMotion, transitionEndEventName } from './utils/environment';
import { getWindowDimensions } from './utils/window';
import debug from './utils/debug';
import Attr from './utils/attributes';
import { addToArray, removeFromArray } from './utils/array';
import throttle from './utils/throttle';

import {
  Globals,
  Page,
  PageLoader,
  BackToTop,
  // Pointer,
  // Cursor,
  Cookie,
  Navigation,
  SmoothScroll,
  // RevealOnScroll,
  Load,
  // HoverList,
  // ImageTrail,
  // Title,
  // Gallery,
  // Lightbox
} from './modules';

import lazySizes               from 'lazysizes';

// lazySizesConfig.loadMode (default: 2): The loadMode can be used to constrain the allowed loading mode. Possible values are 0 = don't load anything, 1 = only load visible elements, 2 = load also very near view elements (expand option) and 3 = load also not so near view elements (expand * expFactor option). This value is automatically set to 3 after onload. Change this value to 1 if you (also) optimize for the onload event or change it to 3 if your onload event is already heavily delayed.
lazySizes.cfg.loadMode = 1;

window.debug = debug;


// debug(window.matchMedia('(prefers-reduced-motion: reduce)').matches);
// const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
// if (connection.saveData || ['slow-2g', '2g', '3g'].includes(connection.effectiveType)) {
//   // prevent loading resources and other data saving measures
// }

// TODO: improve cursor on lightbox image hover


let app = {

    DOM: {
        html: html,
        body: body,
        header: document.getElementById('masthead'),
        main: document.getElementById('page'),
        pageContent: document.getElementById('page-content'),
        primary: document.getElementById('primary'),

        quicklink: body,
    },

    bounds: {
        window_w: 0,
        window_h: 0,
        appOffsetTop: 0
    },

    modules: [],
    pageModules: [],

    scroll: {
        // isScrolling: true,
        last: 0,
        // target: localStorage.getItem('scroll') || 0,
        target: 0,
        // diff: 0,
        // acc: 0,
        // velo: 0,
        // // ease: this.env.isMobile ? 0.35 : 0.075,
        // // ease: this.env.isMobile ? 0.35 : 0.1,
        // ease: 0.1,

        treshold : 30,
        direction: false,
    },

    page: {
        last: '',
        current: '',
        to: '',
    },

    transitionEndEventName: transitionEndEventName,

    init() {
        debug('init the app')
        // this.initScrollPosition = this.scroll.target;

        // Callbacks
        this._resizeCb = (e) => this._onResize(e);
        this._scrollCb = (e) => this._onScroll(e);

        // Vars
        const windowDim = getWindowDimensions();
        this.bounds.window_w = windowDim.width;
        this.bounds.window_h = windowDim.height;
        this.bounds.appOffsetTop = this.DOM.main.offsetTop;
        this.firstInit = true;
        this.scroll.treshold = isMobile ? this.bounds.window_h / 10 : this.bounds.window_h / 3;

        // Classes from environment
        Attr.addClass(html, isIE ? 'is-ie' : 'not-ie');
        Attr.addClass(html, isMobile ? 'is-mobile' : 'not-mobile');
        Attr.addClass(html, cssMixBlendMode ? 'mix-blend-mode' : 'no-mix-blend-mode');
        Attr.addClass(html, intersectionObserverSupport ? 'intersection-observer' : 'no-intersection-observer');
        Attr.addClass(html, reducedMotion ? 'is-reduced-motion' : 'no-reduced-motion');

        // this.scroll.target = this.scroll.last = this.getScrollY();

        // Construct modules
        // this.Example = new Example(this);
        // this.addModule(this.Example);
        this.Globals = new Globals(this);
        this.addModule(this.Globals);
        this.BackToTop = new BackToTop(this);
        this.addModule(this.BackToTop);
        // this.Pointer = new Pointer('#_p', this);
        // this.addModule(this.Pointer);

        this.SmoothScroll = new SmoothScroll(this);
        this.addModule(this.SmoothScroll);
        // this.ROS = new RevealOnScroll('.reveal-on-scroll', this);
        // this.addModule(this.ROS);
        this.PageLoader = new PageLoader(this);
        this.addModule(this.PageLoader);
        this.Load = new Load(this);
        this.addModule(this.Load);
        this.Cookie = new Cookie(this);
        this.addModule(this.Cookie);
        this.Navigation = new Navigation(this);
        this.addModule(this.Navigation);

        // this.Lightbox = new Lightbox(this);
        // this.addModule(this.Lightbox);
        // this.Title = new Title('.js-title', this);
        // this.addModule(this.Title);
        // this.Gallery = new Gallery(this);
        // this.addModule(this.Gallery);

        // if (!isMobile) {
        // this.Cursor = new Cursor('.cursor', this);
        // this.addModule(this.Cursor);
        // this.HoverList = new HoverList('.js-hoverlist', this);
        // this.addModule(this.HoverList);
        // this.ImageTrail = new ImageTrail('.is-style-imagetrail', this);
        // this.addModule(this.ImageTrail);
        // }

        // Init the page
        // this.preloadFonts().then(() => {
        // debug('fonts are preloaded, should call page init');
        this.pageInit();
        // });

        // Misc
        this.copyright();
    },

    pageInit() {
        debug('init the page', this);
        this.DOM.primary = document.getElementById('primary');

        // Page
        // Home page
        if (Attr.hasClass(body, 'home')) {
            this.Page = new Page('Home', this);
        }
        // Single Projet
        // else if (Attr.hasClass(body, 'single-projets')) {
        //     this.Page = new Page('SingleProjets', this);
        // }
        // Other
        else {
            this.Page = new Page('Default', this);
        }
        this.addPageModule(this.Page);

        window.app = this;

        // // Change BG Color if needed
        // if (this.DOM.primary.dataset.customPageColor) {
        //     let color = this.DOM.primary.dataset.customPageColor;
        //     this.DOM.pageContent.style.backgroundColor = color;
        //     debug('change page custom bg color', color);
        // } else {
        //     // this.DOM.pageContent.style.backgroundColor = 'transparent';
        //     this.DOM.pageContent.style.removeProperty('background-color');
        // }

        // Preload Images
        // this.preloadImages().then(() => {
        // debug('images are preloaded, should call init');

        // Initialize all modules
        this.call('init').then(() => {

            // Page is ready
            this.onPageReady().then(() => {
                this.onPageLoaded().then(() => {
                    setTimeout(() => {
                        this.SmoothScroll.setHeight();
                    }, 1000);
                });
            });

        });

        // });
    },

    // Preload Fonts
    // preloadFonts() {
    //   const _this = this;
    //   let _fonts = [
    //     { name: 'Philosopher', data: { weight: 400 } },
    //     { name: 'Philosopher', data: { weight: 700 } },
    //     { name: 'Holland', data: {} },
    //   ];
    //   if (typeof themeFonts !== 'undefined') {
    //     _fonts = themeFonts;
    //   }

    //   var fonts = _fonts;
    //   var observers = [];
    //   this.PageLoader.add(fonts.length);

    //   // Make one observer for each font,
    //   // by iterating over the data we already have
    //   fonts.forEach(font => {
    //     var obs = new FontFaceObserver(font.name, font.data);
    //     observers.push(obs.load().then(() => {
    //       _this.PageLoader.increment();
    //     }));
    //   });

    //   debug('run preload fonts', fonts, observers);
    //   return Promise.all(observers);
    // },

    // Preload images
    // preloadImages() {
    //   const _this = this;
    //   const pr = [];

    //   // Get all data-img="src" elems and preload them (as <img>)
    //   const imgwrap = document.createElement('div');
    //   imgwrap.style.visibility = 'hidden';
    //   body.appendChild(imgwrap);

    //   [...document.querySelectorAll('.has-data-img')].forEach(el => {
    //     const imgEl = document.createElement('img');
    //     imgEl.style.width = 0;
    //     imgEl.src = el.dataset.img;
    //     // imgEl.className = 'preload';
    //     imgwrap.appendChild(imgEl);
    //   });

    //   // Get all images
    //   let images = document.querySelectorAll('img');
    //   let imagesBG = document.querySelectorAll('.has-bg-img');
    //   let nb_images = images.length;
    //   let nb_imagesBG = imagesBG.length;
    //   // debug(images, imagesBG);
    //   // debug(nb_images, nb_imagesBG);
    //   this.PageLoader.add(nb_images + nb_imagesBG);

    //   if (nb_images > 0) {
    //     pr.push(
    //       new Promise((resolve, reject) => {
    //         let il = imagesLoaded(images, () => {
    //           imgwrap.parentNode.removeChild(imgwrap);
    //           resolve();
    //         });

    //         // let il = imagesLoaded(document.querySelectorAll('img'), resolve);

    //         // il.on( 'always', function( instance ) {
    //         //   debug('ALWAYS - all images have been loaded');
    //         // });
    //         il.on( 'done', function( instance ) {
    //           debug('DONE  - all images have been successfully loaded');
    //         });
    //         il.on( 'progress', function( instance, image ) {
    //           var result = image.isLoaded ? 'loaded' : 'broken';
    //           // debug( 'image is ' + result + ' for ' + image.img.src );

    //           _this.PageLoader.increment();
    //         });
    //       })
    //     );
    //   }
    //   if (nb_imagesBG > 0) {
    //     pr.push(
    //       new Promise((resolve, reject) => {
    //         let il = imagesLoaded(imagesBG, { background: true }, resolve);

    //         // il.on( 'always', function( instance ) {
    //         //   debug('ALWAYS - all background images have been loaded');
    //         // });
    //         il.on( 'done', function( instance ) {
    //           debug('DONE  - all background images have been successfully loaded');
    //         });
    //         il.on( 'progress', function( instance, image ) {
    //           var result = image.isLoaded ? 'loaded' : 'broken';
    //           // debug( 'background image is ' + result + ' for ' + image.img.src );

    //           _this.PageLoader.increment();
    //         });
    //       })
    //     );
    //   }

    //   debug('run preload images', pr);
    //   return Promise.all(pr);
    // },

    _addListeners() {
        window.addEventListener('resize', throttle(this._resizeCb, 200), { passive: true });
        window.addEventListener('scroll', throttle(this._scrollCb, 30));

        // window.addEventListener('beforeunload', () => {
        //   localStorage.setItem('scroll', this.scroll.target);
        // });
    },

    _removeListeners() {
        window.removeEventListener('resize', this._resizeCb, { passive: true });
        window.removeEventListener('scroll', this._resizeCb);
    },


    //--- Hooks

    onPageReady() {
        if (this.firstInit) {
            this.firstInit = false;
            // Listeners
            this._addListeners();
        }

        return this.call('_onPageReady');
    },

    onPageLoaded() {
        return this.call('_onPageLoaded');
    },

    onPageOut() {
        return this.call('_onPageOut');
    },

    onPageIn() {
        return this.call('_onPageIn');
    },

    onPageChange() {
        return this.call('_onPageChange').then(() => {
            // Destroy all modules
            this.call('destroy').then(() => {
                // Remove page specific modules
                this.pageModules.forEach(m => {
                    // debug('try to remove module', m, this.modules);
                    removeFromArray(this.modules, m);
                });
                this.pageModules = [];
            });
        })
    },

    _onScroll(e) {
        // debug(e);
        // this.scroll.isScrolling = true;
        this.scroll.last = this.scroll.target;
        this.scroll.target = this.getScrollY();

        // Scroll Direction
        if ( this.scroll.target > this.scroll.last + 10 ) {
            this.scroll.direction = 'down';
            body.classList.add('scroll-down');
            body.classList.remove('scroll-up');
        } else if ( this.scroll.target < this.scroll.last - 10 ) {
            this.scroll.direction = 'up'
            body.classList.add('scroll-up');
            body.classList.remove('scroll-down');
        }

        // Has Scroll ?
        // if ( this.scroll.target > this.bounds.appOffsetTop ) {
        if ( this.scroll.target > this.scroll.treshold ) {
            // Util.addClass(this.el.body, CLASSES.hasScroll);
            body.classList.add('hasScroll');
        } else {
            // Util.removeClass(this.el.body, CLASSES.hasScroll);
            body.classList.remove('hasScroll');
        }

        this.call('_onScroll');
    },

    _onResize(e) {
        // debug(e);

        const windowDim = getWindowDimensions();
        this.bounds.window_w = windowDim.width;
        this.bounds.window_h = windowDim.height;
        this.bounds.appOffsetTop = this.DOM.main.offsetTop;
        this.scroll.treshold = isMobile ? this.bounds.window_h / 10 : this.bounds.window_h / 3;

        this.call('_onResize');
    },


    //--- Helpers

    getScrollY () {
        return window.scrollY || document.documentElement.scrollTop;
    },

    addModule(module) {
        addToArray(this.modules, module);
    },

    addPageModule(module) {
        addToArray(this.pageModules, module);
        addToArray(this.modules, module);
    },

    call(func) {
        debug('call ' + func);

        if (func === '_onResize' || func === '_onScroll') {
            this.modules.forEach(m => {
                if (typeof m[func] === 'function') {
                    m[func]();
                }
            });
        } else {
            let pr = [];
            this.modules.forEach(m => {
                if (typeof m[func] === 'function') {
                    pr.push(m[func]());
                }
            });
            return Promise.all(pr).then(() => {
                debug('all call ' + func + ' ended');
            });
        }
    },

    copyright () {
        const style = [
            'color: #fff',
            'background: #ffc308',
            'padding: 4px 8px',
            'border-left: 5px solid #282828',
            'border-right: 5px solid #282828'
        ].join(';')

        console.log('%cA creation by Phantasme 🇫🇷', style)
        console.log('%cCopyright © 2021 • Tous droits réservés.', style)

        // console.log('%cDesign by Salopard Parisien', style)
        // console.log('%c> Site : https://salopardparisien.com/', style)
        // console.log('%c> Instagram : https://www.instagram.com/agencesalopardparisien/', style)
        // console.log('%c> Facebook : https://www.facebook.com/salopardparisien/', style)

        console.log('%c> Development by Boite à Oeufs', style)
        console.log('%c> Site : https://www.boite-a-oeufs.com', style)
        console.log('%c> Twitter : https://twitter.com/BoiteOeufs', style)
        console.log('%c> Facebook : https://www.facebook.com/boiteoeufs/', style)
    },

}
window.app = app;


function initialize() {
    app.init();
    // console.log('app is init');

    // // Check styles
    // // /app/themes/sp-t3/assets/css/style-first.css
    // const $style = document.getElementById('bao-style-first-css');

    // if ($style) {
    //   if ($style.isLoaded) {
    //     // debug('style is already loaded');
    //     console.log('style is already loaded');
    //     app.init();
    //   } else {
    //     // debug('waiting for style');
    //     console.log('waiting for style');
    //     $style.addEventListener('load', (event) => {
    //       // debug('style is loaded');
    //       console.log('style is loaded');
    //       app.init();
    //     });
    //   }
    // } else {
    //   console.warn('The "critical-css" stylesheet not found.');
    // }

    // Register Service Worker
    if ('serviceWorker' in navigator) {
        const scope = '/'

        // Delay registration until after the page has loaded, to ensure that our
        // precaching requests don't degrade the first visit experience.
        // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
        // window.addEventListener('load', function () {

            // Your service-worker.js *must* be located at the top-level directory relative to your site.
            // It won't be able to control pages unless it's located at the same level or higher than them.
            // See https://github.com/slightlyoff/ServiceWorker/issues/468
            navigator.serviceWorker.register('/serviceworker.js', { scope: scope }).then(function(reg) {
                // debug('Service Worker registered successfully.')
            }).catch(function(e) {
                // console.error('Error during service worker registration:', e);
            })

        // })


        // // Detect if site launch via PWA
        // if (
        //   window.matchMedia('(display-mode: standalone)').matches ||
        //   window.navigator.standalone === true // for Safari
        // ) {
        //   debug('display-mode is standalone');
        // }
        // // // And same in CSS
        // // @media all and (display-mode: standalone) {
        // //   body {
        // //     background-color: yellow;
        // //   }
        // // }

        // let deferredPrompt;

        // window.addEventListener('beforeinstallprompt', (e) => {
        //   debug('test from beforeinstallprompt');

        //   // Stash the event so it can be triggered later.
        //   deferredPrompt = e;
        //   // Update UI notify the user they can add to home screen
        //   showInstallPromotion();
        //   // prompt();
        // });

        // window.addEventListener('appinstalled', (evt) => {
        //   debug('PWA installed');
        // });
    }
}

// console.log(app);

// Window loaded
// if (window._is_loaded) {
//   // debug('window already loaded');
//   console.log('window already loaded');
//   initialize();
// } else {
//   window.onload = (event) => {
//     // debug('on window loaded');
//     console.log('on window loaded');
//     initialize();
//   };
// }
initialize();

// If the cache is loaded via bfcache (back/forward cache)
// https://web.dev/bfcache/
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        debug('This page was restored from the bfcache.');
        // console.log('This page was restored from the bfcache.');
        initialize();
    } else {
        // console.log('This page was loaded normally.');
    }
});


// window.onbeforeunload = function (e) {
//   // e = e || window.event;

//   // // For IE and Firefox prior to version 4
//   // if (e) {
//   //     e.returnValue = 'Sure?';
//   // }

//   // // For Safari
//   // return 'Sure?';

//   localStorage.clear();
// };



// function sleep(milliseconds) {
//   const date = Date.now();
//   let currentDate = null;
//   do {
//     currentDate = Date.now();
//   } while (currentDate - date < milliseconds);
// }
// sleep(5000);
